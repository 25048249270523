<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">{{$t('views.printers.printerList')}}</div>

            <div class="row">
              <div class="col-lg-12">
                <div class="printers--actions">
                  <div class="search">
                    <div class="form-group">
                      <input v-model="searchString" type="text" :placeholder="$t('navbar.search.text')" class="form-control form-control-sm">
                    </div>
                  </div>
                  <div class="create">
                    <div @click="showAddPopup" class="btn btn-sm btn-success">
                      <i class="fas fa-plus"></i> {{$t('popularWords.add')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table table-hover" style="cursor: pointer;">
                  <thead>
                  <tr>
                    <th>Тип</th>
                    <th>{{$t('popularWords.sn')}}</th>
                    <th>IP</th>
                    <th>{{$t('views.printers.port')}}</th>
                    <th>{{$t('views.printers.code')}}</th>
                    <th>{{$t('views.printers.isOn')}}</th>
                    <th>{{$t('views.printers.showMap')}}</th>
                    <th>{{$t('popularWords.color')}}</th>
                    <th>{{$t('views.printers.production')}}</th>
                    <th>{{$t('views.printers.address')}}</th>
                    <th>{{$t('views.printers.actions')}}</th>
                    <th>{{$t('views.printers.notes')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in computedPrinters" :key="item.serial_number">
                    <td @click="showEditPopup(Object.assign({}, item))"><i v-if="item.type === 'common'" class='fas fa-file'></i><i v-if="item.type !== 'common'" class='fas fa-image'></i></td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.serial_number}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.ip}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.port}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.code}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.is_on}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.show_map}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.is_color}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.is_production}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.printerLocation ? item.printerLocation.address : ""}}</td>
                    <td>
                      <div @click.prevent="showRemovePopup(item.id)" class="btn btn-sm btn-danger">
                        <i class="fa fa-times"></i>
                      </div>
                    </td>
                    <td>
                      <div @click="showShowNotesPopup(item.notes)" v-if="item.notes != '' && item.notes != null" class="btn btn-sm">
                        <i class="far fa-comment-dots"></i>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <popup
      :closeButton="addPrinterPopup.closeButton"
      :actionButton="addPrinterPopup.actionButton"
      :actionClass="addPrinterPopup.actionClass"
      :show="addPrinterPopup.show"
      @closePopup="closeAddPopup"
      @actionPopup="addPopupSubmit"
    >
      <div slot="header">{{$t('views.printers.createPrinter')}}</div>
      <div slot="body">
        <create-printer
          :currency-list="currency_list"
          :timezones="timezones"
          :legal-relations="legal_relations"
          :submit-printer="submitCreatePrinter"
          @submitCreatePrinter="createPrinter"
        ></create-printer>
      </div>
    </popup>

    <popup
        :closeButton="editPrinterPopup.closeButton"
        :actionButton="editPrinterPopup.actionButton"
        :actionClass="editPrinterPopup.actionClass"
        :show="editPrinterPopup.show"
        @closePopup="closeEditPopup"
        @actionPopup="editPopupSubmit"
    >
      <div slot="header">{{$t('views.printers.editPrinter')}}</div>
      <div slot="body">
        <edit-printer
            :printer="editPrinter"
            :currency-list="currency_list"
            :timezones="timezones"
            :legal-relations="legal_relations"
            :submit-printer="submitEditPrinter"
            @submitEditPrinter="savePrinter"
        ></edit-printer>
      </div>
    </popup>


    <popup
      :closeButton="removePrinterPopup.closeButton"
      :actionButton="removePrinterPopup.actionButton"
      :actionClass="removePrinterPopup.actionClass"
      :show="removePrinterPopup.show"
      @closePopup="closeRemovePopup"
      @actionPopup="removePopupSubmit"
    >
      <div slot="header">{{$t('views.printers.deletePrinter')}}</div>
      <div slot="body">
        {{$t('views.printers.deletePrinterText')}}
      </div>
    </popup>

    <popup
      :closeButton="showNotesPopup.closeButton"
      :show="showNotesPopup.show"
      @closePopup="closeShowNotesPopup"
    >
      <div slot="header">{{$t('views.printers.notes')}}</div>
      <div slot="body">
        {{clickedNotes}}
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Popup from "@/components/Popup";
import EditPrinter from "@/components/management/printers/EditPrinter";
import CreatePrinter from "@/components/management/printers/CreatePrinter";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";

const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    EditPrinter,
    CreatePrinter,
    Popup,
    Layout,
    PageHeader,
  },
  data() {
    return {
      printers: [],
      selectedFranchises: [],
      searchString: '',
      editPrinter: {},
      addPrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editPrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      removePrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      showNotesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close')
      },
      clickedNotes: "",
      deletedPrinterId: 0,
      currency_list: [],
      //metro_stations: [],
      timezones: {},
      franchises: [],
      legal_relations: [],
      submitted: false,
      submitEditPrinter: 0,
      submitCreatePrinter: 0,
      title: this.$t('menuitems.management.list.printers'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.printers'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {
    computedPrinters(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.printers){
        if(searchStr == ''){
          result[result.length] = this.printers[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          let serialNumber = ''+this.printers[key].serial_number +'';
          if(regexp.test(serialNumber)){
            isSearched = true;
            result[result.length] = this.printers[key];
          }
          if(!isSearched){
            let ip = ''+this.printers[key].ip +'';
            if(regexp.test(ip)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let code = ''+this.printers[key].code +'';
            if(regexp.test(code)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let address = ''+this.printers[key].printerLocation.address +'';
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let name = ''+this.printers[key].printerLocation.name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    closeShowNotesPopup(){
      this.showNotesPopup.show = false;
    },
    showShowNotesPopup(notes){
      this.clickedNotes = notes;
      this.showNotesPopup.show = true;
    },
    closeAddPopup(){
      this.addPrinterPopup.show = false;
    },
    showAddPopup(){
      this.addPrinterPopup.show = true;
    },
    addPopupSubmit(){
      this.submitCreatePrinter++;
    },
    createPrinter(data){
      let formData = new FormData();
      for(let key in data){
        if( typeof data[key] === 'object'){
          formData.append(key, JSON.stringify(data[key]));
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/printers/create`, formData)
          .then(() => {
            this.closeAddPopup();
            this.getPrinters();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    closeRemovePopup(){
      this.removePrinterPopup.show = false;
    },
    showRemovePopup(printerId){
      this.deletedPrinterId = printerId;
      this.removePrinterPopup.show = true;
    },
    removePopupSubmit(){
      let formData = new FormData();
      formData.append("printer_id", this.deletedPrinterId);
      axios
          .post(`/v1/printers/delete`, formData)
          .then(() => {
            this.closeRemovePopup();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
            this.getPrinters();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    savePrinter(val){
      let formData = new FormData();
      for(let key in val){
        if( typeof val[key] === 'object'){
          formData.append(key, JSON.stringify(val[key]));
        }else{
          formData.append(key, val[key]);
        }
      }
      axios
          .post(`/v1/printers/update`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getPrinters();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editPopupSubmit(){
      this.submitEditPrinter++;
    },
    showEditPopup(obj){
      let copyObj = copyArrObj.copy(obj);
      this.editPrinter = copyObj;
      this.editPrinterPopup.show = true;
    },
    closeEditPopup(){
      this.editPrinterPopup.show = false;
    },
    getPrinters(){
      let formData = new FormData();
      formData.append("selected_franchises", JSON.stringify(this.selectedFranchises));
      axios
          .post(`/v1/printers/get-all`, formData)
          .then(resp => {
            this.printers = resp.data;
            for(let key in this.printers){
              this.printers[key].printerLocation.metro_station_id = '' + this.printers[key].printerLocation.metro_station_id + '';
            }
            this.getCurrencyList();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response ? err.response.data.message : "",
              time: 6,
              color: "danger"
            });
          });
    },
    getCurrencyList(){
      axios
          .get(`/v1/configs/currency`)
          .then(resp => {
            this.currency_list = resp.data;
            //this.getMetroStations();
            this.getTimezones();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    // getMetroStations(){
    //   axios
    //       .get(`/v1/configs/metro-stations`)
    //       .then(resp => {
    //         this.metro_stations = resp.data;
    //         this.getTimezones();
    //       })
    //       .catch(err => {
    //         this.$store.dispatch("addNotification", {
    //           text: err.response.data.message,
    //           time: 6,
    //           color: "danger"
    //         });
    //       });
    // },
    getTimezones(){
      axios
          .get(`/v1/configs/timezones`)
          .then(resp => {
            this.timezones = resp.data;
            //this.getFranchisies();
            this.getLegalRelations();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getFranchisies(){
      axios
          .get(`/v1/franchise/get-all`)
          .then(resp => {
            this.franchises = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getLegalRelations(){
      axios
          .get(`/v1/legal-relations/get-all`)
          .then(resp => {
            this.legal_relations = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getPrinters();
  }
};
</script>

<style scoped lang="scss">
.printers--actions{
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;

  .search{
    margin-right: 15px;
  }
}
</style>
